/**
 * FullStory is currently injected into the app using our integration with Segment.
 * This is not ideal, but it is the current state of things. Creating this service
 * to abstract the FullStory API will allow us to easily swap out the implementation
 * with the official FullStory SDK in the future.
 * 
 * @module services/fullstory
 * @see https://developer.fullstory.com/browser/v1/get-session-details/
 * @see https://developer.fullstory.com/browser/get-session-details/
 */

interface FullstoryV1 {
  identify(uid: string, userVars?: object): void;
  setUserVars(userVars: object): void;
  getCurrentSessionURL(now?: boolean): string;
  log(msg: string): void;
  log(level: string, msg: string): void;
  shutdown(): void;
  restart(): void;
  consent(consent: boolean): void;
  event(eventName: string, eventProperties: object): void;
}

interface FullstoryV2 {
  (cmd: 'getCurrentSessionURL', options: { format: 'url' | 'url.now' }): string;
  (cmd: 'getSession', options: { format: 'url' | 'url.now' }): string;
}

declare global {
  interface Window {
    FS: FullstoryV1 | FullstoryV2;
  }
};

export const getCurrentSessionURL = (includeTime = false): string => {
  const fs = window.FS;

  // Support for FullStory's v1 API
  if (fs && 'getCurrentSessionURL' in fs) {
    return fs.getCurrentSessionURL(includeTime);
  }
  // Support for FullStory's v2 API
  if (fs) {
    return fs('getSession', {
      format: includeTime ? 'url.now' : 'url',
    })
  }

  return '';
};

export const getCurrentSessionURLAtTime = (): string => {
  return getCurrentSessionURL(true);
};
